import "../App.css";
import { Box } from "grommet";
export const IntroText = function () {
  return;
};

function Home() {
  return (
    <div>
      <div>
        <Box height={"20px"}></Box>
        <Box height={"2000px"} direction={"column-responsive"}>
          <Box height={"2000px"} flex={"grow"} width={"50%"}>
            <div
              class="amplenote-embed"
              data-note-token="oV7jfvxRucsyBkbQrdqWxMTo"
            >
              <iframe
                style={{ borderRadius: "20px" }}
                title="XB"
                width="90%"
                height="700px"
                frameBorder={0}
                src="https://public.amplenote.com/embed/oV7jfvxRucsyBkbQrdqWxMTo?hostname=xanderbot.com&styles=%2FAmplenote.css"
              ></iframe>
            </div>
            <script defer src="https://public.amplenote.com/embed.js"></script>
          </Box>
        </Box>
      </div>
    </div>
  );
}

export default Home;
